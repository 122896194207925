<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3787 9.16408C15.6327 9.16408 16.6499 8.14753 16.6499 6.89353C16.6499 5.63953 15.6327 4.62299 14.3787 4.62299" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.4229 11.6315C15.7973 11.6574 16.1697 11.7105 16.5362 11.793C17.0456 11.8927 17.6582 12.1014 17.8763 12.5584C18.0155 12.8511 18.0155 13.1919 17.8763 13.4853C17.659 13.9423 17.0456 14.1503 16.5362 14.255" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.53127 9.16408C4.27727 9.16408 3.26001 8.14753 3.26001 6.89353C3.26001 5.63953 4.27727 4.62299 5.53127 4.62299" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.48712 11.6315C4.11264 11.6574 3.74032 11.7105 3.37373 11.793C2.86438 11.8927 2.25173 12.1014 2.03436 12.5584C1.89447 12.8511 1.89447 13.1919 2.03436 13.4853C2.25101 13.9423 2.86438 14.1503 3.37373 14.255" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.95133 12.1205C12.4923 12.1205 14.6632 12.505 14.6632 14.0438C14.6632 15.5819 12.5067 15.9808 9.95133 15.9808C7.40961 15.9808 5.2395 15.5963 5.2395 14.0575C5.2395 12.5187 7.39598 12.1205 9.95133 12.1205Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.95138 9.92559C8.27556 9.92559 6.93188 8.58191 6.93188 6.90537C6.93188 5.22955 8.27556 3.88587 9.95138 3.88587C11.6272 3.88587 12.9709 5.22955 12.9709 6.90537C12.9709 8.58191 11.6272 9.92559 9.95138 9.92559Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default{
    props:{
        fill:{
            type:String,
            default:'text-gray-400'
        }
    }
}
</script>
