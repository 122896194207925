<template>
  <div class="h-full overflow-x-hidden">
    <ConfettiExplosion :particleCount="200" :force="0.2" v-if="(daysLeftToDraw==0 && balanceOpened) || forceConfetti"/>
    <div class="bg-white rounded-lg px-4 py-0 sm:px-6 flex flex-col justify-around w-full">
      <div v-if="balanceOpened">
        <!-- counter -->
        <div v-if="daysLeftToDraw>0 && !showLastDraw" class="rounded-lg text-center m-auto mt-0 mb-12 relative w-full" @click="toggleLastDraw()">
          <div class="relative circle">
          <div id="outer-circle" :style="'background: conic-gradient(#4CB8C2 '+loadingCircleDegs+'deg, #B7E2E6 '+(loadingCircleDegs+1)+'deg);'"></div>
            <div id="inner-circle" class="absolute m-auto bg-white rounded-full flex justify-center items-center flex-col m-auto">
              <h1 class="text-6xl font-bold text-green-800">{{dLeft}}</h1>
              <span v-if="daysLeftToDraw>2" class="text-2xl font-bold text-green-500">days</span>
              <span v-else class="text-2xl font-bold text-green-500">day</span>
              <span class="text-green-800">{{hLeft}}h {{mLeft}}m {{sLeft}}s</span>
              <span class="text-xs text-gray-400">Tap to see last draw</span>
            </div>
          </div>
          <div class="absolute w-full text-center">
            <p class="text-xl text-gray-800 text-center m-auto text-center">Until the next draw</p>
          </div>
          

        </div>
        <!-- prize day -->
        <div v-if="daysLeftToDraw==0 && !showNewCounter" class="mb-12">
          <div @click="toggleLastPrize()"  class="rounded-full mt-0 flex justify-center items-center flex-col relative circle" >
            <div id="circle-prize-outer"></div>
              <div id="circle-prize-inner" class="rounded-full flex justify-center items-center flex-col">
                <h1 class="text-3xl text-gray-800 font-semibold">You won</h1>
                <h1 v-if="showLastDraw" class="text-5xl text-gray-800 font-semibold">€ {{lastPrize}}!</h1>
                <h1 v-else class="text-5xl text-gray-800 font-semibold">€ {{prize}}!</h1>
                <span class="text-xs text-gray-400 w-32">Tap to go to the next countdown</span>
              </div>
            </div>
          
          <!-- <p class="text-xl text-gray-800 text-center">Prize draw day!</p> -->
        </div>

        <!-- showLastDraw -->
            <div v-if="showLastDraw" class="mb-12">
          <div @click="showLastDraw=!showLastDraw"  class="rounded-full mt-0 flex justify-center items-center flex-col relative circle" >
            <div id="circle-prize-outer"></div>
              <div id="circle-prize-inner" class="rounded-full flex justify-center items-center flex-col">
                <span class="text-xs text-gray-400 w-32">Last draw</span>
                <h1 class="text-3xl text-gray-800 font-semibold">You won</h1>
                <h1 v-if="showLastDraw" class="text-5xl text-gray-800 font-semibold">€ {{lastPrize}}!</h1>
                <h1 v-else class="text-5xl text-gray-800 font-semibold">€ {{prize}}!</h1>
                <span class="text-xs text-gray-400 w-32">Tap to get back to the countdown</span>
              </div>
            </div>
          
          <!-- <p class="text-xl text-gray-800 text-center">Prize draw day!</p> -->
        </div>

        <!-- showNextCounter -->
        <div v-if="showNewCounter" class="rounded-lg text-center m-auto mt-0 mb-12 relative w-full" @click="showNewCounter=!showNewCounter">
          <div class="relative circle">
          <div id="outer-circle" :style="'background: conic-gradient(#4CB8C2 '+loadingCircleDegsNext+'deg, #B7E2E6 '+(loadingCircleDegsNext+1)+'deg);'"></div>
            <div id="inner-circle" class="absolute m-auto bg-white rounded-full flex justify-center items-center flex-col m-auto">
              <h1 class="text-6xl font-bold text-green-800">{{dLeft+7}}</h1>
              <span v-if="(daysLeftToDraw+7)>2" class="text-2xl font-bold text-green-500">days</span>
              <span v-else class="text-2xl font-bold text-green-500">day</span>
              <span class="text-green-800">{{(hLeft+24)}}h {{(mLeft+60)}}m {{(sLeft+60)}}s</span>
              <span class="text-xs text-gray-400 w-20">Tap to see the prize draw</span>
            </div>
          </div>
          <div class="absolute w-full text-center">
            <p class="text-xl text-gray-800 text-center m-auto text-center">Until the next draw</p>
          </div>
        </div>
      </div>
      <div v-else class="rounded-lg text-center m-auto mt-0 mb-20 relative w-full">
          <div class="relative circle opacity-30" v-if="daysLeftToDraw==0">
            <div id="outer-circle" :style="'background: conic-gradient(#4CB8C2 '+loadingCircleDegsNext+'deg, #B7E2E6 '+(loadingCircleDegsNext+1)+'deg);'"></div>
              <div id="inner-circle" class="absolute m-auto bg-white rounded-full flex justify-center items-center flex-col m-auto">
                <h1 class="text-6xl font-bold text-green-800">{{dLeft+7}}</h1>
                <span v-if="(daysLeftToDraw+7)>2" class="text-2xl font-bold text-green-500">days</span>
                <span v-else class="text-2xl font-bold text-green-500">day</span>
                <span class="text-green-800">{{(hLeft+24)}}h {{(mLeft+60)}}m {{(sLeft+60)}}s</span>
                <span class="text-xs text-gray-400 w-20">Tap to see the prize draw</span>
              </div>
          </div>
          <div class="relative circle opacity-30" v-else>
          <div id="outer-circle" :style="'background: conic-gradient(#4CB8C2 '+loadingCircleDegs+'deg, #B7E2E6 '+(loadingCircleDegs+1)+'deg);'"></div>
            <div id="inner-circle" class="absolute m-auto bg-white rounded-full flex justify-center items-center flex-col m-auto">
              <h1 class="text-6xl font-bold text-green-800">{{dLeft}}</h1>
              <span v-if="daysLeftToDraw>2" class="text-2xl font-bold text-green-500">days</span>
              <span v-else class="text-2xl font-bold text-green-500">day</span>
              <span class="text-green-800">{{hLeft}}h {{mLeft}}m {{sLeft}}s</span>
              <span class="text-xs text-gray-400">Tap to see last draw</span>
            </div>
          </div>

          <div class="absolute w-full text-center">
            <p class="text-xl text-gray-800 text-center m-auto text-center">Make your first deposit </p>
            <p class="text-xl text-gray-800 text-center m-auto text-center">to start winning prizes! </p>
            
          </div>
        </div>
      <!-- <div v-else class="mb-8">
        <div class="relative circle">
          <div id="circle-prize-empty-outer"></div>
            <div id="circle-prize-inner" class="rounded-full flex justify-center items-center flex-col">
              <span class="text-lg text-gray-700 w-32 font-semibold">Prize not available yet!</span>
              <span class="text-sm mt-4 text-gray-400 w-32">Deposit now to start using Savvy</span>
            </div>
          </div>
      </div> -->

      
      <div class="mb-8">
       <div class="mt-2 py-4 px-2 grid grid-flow-col gap-1 w-full justify-between items-center auto-cols-max border border-gray-200 rounded-xl rounded text-center">
          <ValutIcon class="w-10 h-10 p-2 bg-green-500 rounded-full text-white" :fill="'#3697A0'"/>
          <p class="text-gray-500 text-left text-xs ml-1 w-36">Save and get more tickets into future draws</p>
          <button @click="this.$router.push('deposit')" type="button" class="w-24 px-2 inline-flex inline-flex py-2.5 justify-center m-auto items-center border border-transparent shadow-sm text-xs font-medium rounded-xl text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Deposit now
          </button>
        </div>
        <div class="mt-2 py-4 px-2 grid grid-flow-col gap-1 w-full justify-between items-center auto-cols-max border border-gray-200 rounded-xl rounded text-center">
            <PersonAddIcon class="w-10 h-10 p-2 bg-green-500 rounded-full text-white " :fill="'#3697A0'"/>
          <span class="text-gray-500 text-left text-xs ml-1 w-36">Improve your odds by inviting more friends</span>
          <button @click="this.$router.push('invite')" type="button" class="w-24  px-2 inline-flex  py-2.5 justify-center m-auto items-center border border-transparent shadow-sm text-xs font-medium rounded-xl text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">            
            Invite a friend
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValutIcon from '@/assets/icons/deposit.vue'
import PersonAddIcon from '@/assets/icons/people.vue'
import ConfettiExplosion from "vue-confetti-explosion";

export default  {
  
  components:{
    ValutIcon,
    PersonAddIcon,
    ConfettiExplosion
  },

  mounted(){
    //set next draw day
    let resultDate= new Date()
    resultDate.setHours(0,0,0,0);
    this.drawTime = resultDate.setDate(resultDate.getDate() + (7 + 0 - resultDate.getDay()) % 7);
    this.timeDistance = this.drawTime - new Date().getTime()
    this.timeDistance2= 7*24*60*60 - (this.drawTime - new Date().getTime())

    //set circle loading indicator
    this.loadingCircleDegs=this.calcCircleDegs()
    this.loadingCircleDegsNext=this.calcCircleDegsNext()

    //set countdown
    setInterval(() => {
        this.timeDistance = this.drawTime - new Date().getTime()
      }, 1000)

    //faking drawing if draw day
    if(this.daysLeftToDraw==0 && !this.$store.state.prizeDrawed && this.balanceOpened){
      this.addInterestFake()
      this.prizeDrawFake()
      this.$store.commit('setPrizeDrawed', true)
    }
  },

  watch:{
    timeDistance(){
      this.dLeft=this.counterDaysLeftToDraw()
      this.hLeft=this.counterHoursLeftToDraw()
      this.mLeft=this.counterMinutesLeftToDraw()
      this.sLeft=this.counterSecondsLeftToDraw()
    }
  },

  computed:{
    daysLeftToDraw(){
      //get day number
      if(new Date().getDay()>0) return 7 - new Date().getDay()
      return new Date().getDay()
    },
    prize(){
      return this.$store.state.prize
    },
    lastPrize(){
      return this.$store.state.lastDraw
    },
    balanceOpened(){
      if(this.$store.state.userBalance.balance>0 && this.$store.state.userBalance.tickets>0) return true
      return false
    }

  },

  data(){
    return{
      loadingCircleDegs:0,
      loadingCircleDegsNext:0,
      drawTime:new Date(),
      dLeft:0,
      hLeft:0,
      mLeft:0,
      sLeft:0,
      timeDistance: this.drawTime - new Date().getTime(),
      timeDistance2: this.drawTime + new Date().getTime(),
      // confetti:false,
      showLastDraw:false,
      showNewCounter:false,
      forceConfetti:false,
      // daysLeftToDraw:0
    }
  },
  methods:{
    toggleLastDraw(){
      if(this.daysLeftToDraw>0 && !this.showLastDraw){
        this.showLastDraw=!this.showLastDraw
        if(this.showLastDraw){
          this.forceConfetti=true
          setTimeout(() => {
            this.forceConfetti=false
          }, 3000);
        }
        else{
          this.forceConfetti=false
        }
      }
      if(this.daysLeftToDraw==0){
        this.showLastDraw=!this.showLastDraw
        if(this.showLastDraw){
          this.forceConfetti=true
          setTimeout(() => {
            this.forceConfetti=false
          }, 3000);
        }
        else{
          this.forceConfetti=false
        }
      }
    },
    toggleLastPrize(){
      this.showNewCounter=!this.showNewCounter
    },
    prizeDrawFake(){
      let prize = this.getRandomIntInclusive(0,300)
      this.$store.commit('setPrize', prize)
      //deposit przie
       this.$store.commit('addDeposit', {
        deposit:prize,
        tickets: prize
      })
      this.$store.commit('addItemToActionList', {
        type:'prize',
        value: prize
      })
    },
    addInterestFake(){
      let interest = (this.$store.state.userBalance.balance*0.005/52).toFixed(2)
      //deposit przie
       this.$store.commit('addDeposit', {
        deposit:interest,
        tickets: Math.floor(interest)
      })
      this.$store.commit('addItemToActionList', {
        type:'interest',
        value: interest
      })
    },
    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    calcCircleDegs(){
      let weekTimeMs = 7*24*60*60*1000
      // console.log(weekTimeMs)
      // console.log(this.timeDistance)
      let ratioOfTimePass = (weekTimeMs - this.timeDistance)/weekTimeMs
      // console.log(ratioOfTimePass)
      let dags = Math.floor(360*ratioOfTimePass)
      return dags
    },
    calcCircleDegsNext(){
      let weekTimeMs = 7*24*60*60*1000
      // console.log(weekTimeMs)
      // console.log(this.timeDistance)
      let ratioOfTimePass = (weekTimeMs-(weekTimeMs - this.timeDistance2))/weekTimeMs
      // console.log(ratioOfTimePass)
      let dags = Math.floor(360*ratioOfTimePass)
      return dags
    },
    counterDaysLeftToDraw(){
      return Math.floor(this.timeDistance / (1000 * 60 * 60 * 24));
    },
    counterHoursLeftToDraw(){
      return Math.floor((this.timeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    },
    counterMinutesLeftToDraw(){
      return Math.floor((this.timeDistance % (1000 * 60 * 60)) / (1000 * 60));
    },
    counterSecondsLeftToDraw(){
      return Math.floor((this.timeDistance % (1000 * 60)) / 1000);
    },
  }
}
</script>
<style scoped>
#outer-circle{
  position: absolute;
  height:100%;
  width: 100%;
  margin:auto;
  border-radius: 50%;
}

#inner-circle{
  position: absolute;
  width: 80%;
  height: 80%;
}

#circle-prize-outer{
  position: absolute;
  height:100%;
  width: 100%;
  margin:auto;
  border-radius: 50%;
  background:#FFD05B;
}

#circle-prize-inner{
  position: absolute;
  width: 80%;
  height: 80%;
  background:#fff6de;
}

#circle-prize-empty-outer{
  position: absolute;
  height:100%;
  width: 100%;
  margin:auto;
  border-radius: 50%;
  background:#fdfaf0;
}

.circle {
  position: relative;
  display: block;
  margin: 1em 3em  0.5em 3em;
  background-color: transparent;
  color: #222;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle:after {
  display: block;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #ddd;
  content: "";
}

.circle__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle__wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.circle__content {
  display: table-cell;
  padding: 1em;
  vertical-align: middle;
}
</style>